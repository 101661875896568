(function () {
  'use strict';

  angular
  .module('neo.home.votacio')
  .config(config);

  function config($stateProvider) {
    $stateProvider.state('home.votacio', {
      url: '/votacio/{idPartit}/{idPerfil}/{idEquip}/{idEquip2}',
     
          templateUrl: 'home/votacio/votacio.tpl.html',
          controller: 'votacioCtrl',
          controllerAs: 'vm',
      resolve:{
       
        resultat: function ( resultats,$stateParams){
          return resultats.query({idPartit :  $stateParams.idPartit}).$promise;
    }, items:function ( items,$stateParams){
          return items.query({idPerfil :  $stateParams.idPerfil}).$promise;
    },
    perfil: function($stateParams){
        return $stateParams.idPerfil;
    },
    equip: function($stateParams){
        return $stateParams.idEquip;
    },
    valoraciones: function(valoracions,$stateParams){
        return valoracions.query({idpartit:$stateParams.idPartit,idequip:$stateParams.idEquip,idperfil:$stateParams.idPerfil}).$promise;
    },
    valoraciones2: function(valoracions,$stateParams){
        return valoracions.query({idpartit:$stateParams.idPartit,idequip:$stateParams.idEquip2,idperfil:$stateParams.idPerfil}).$promise;
    },
    partit: function($stateParams){
        return $stateParams.idPartit;
    }
      }
       
    }).state('home.votaciotecnic', {
      url: '/votaciotecnic/{idPartit}/{idPerfil}/{idEquip}',
     
          templateUrl: 'home/votacio/votaciotecnic.tpl.html',
          controller: 'votaciotecnicCtrl',
          controllerAs: 'vm',
      resolve:{
       
        resultat: function ( resultats,$stateParams){
          return resultats.query({idPartit :  $stateParams.idPartit}).$promise;
    }, items:function ( items,$stateParams){
          return items.query({idPerfil :  $stateParams.idPerfil}).$promise;
    },
    perfil: function($stateParams){
        return $stateParams.idPerfil;
    },
    equip: function($stateParams){
        return $stateParams.idEquip;
    },
    valoraciones: function(valoracions,$stateParams){
        return valoracions.query({idpartit:$stateParams.idPartit,idequip:$stateParams.idEquip,idperfil:$stateParams.idPerfil}).$promise;
    },
    
    partit: function($stateParams){
        return $stateParams.idPartit;
    }
      }
       
    }).state('home.votaciotutorgrada', {
      url: '/votaciotutorgrada/{idPartit}/{idPerfil}/{idEquip}',
     
          templateUrl: 'home/votacio/votaciotutorgrada.tpl.html',
          controller: 'votaciotutorgradaCtrl',
          controllerAs: 'vm',
      resolve:{
       
        resultat: function ( resultats,$stateParams){
          return resultats.query({idPartit :  $stateParams.idPartit}).$promise;
    }, items:function ( items,$stateParams){
          return items.query({idPerfil :  $stateParams.idPerfil}).$promise;
    },
    perfil: function($stateParams){
        return $stateParams.idPerfil;
    },
    equip: function($stateParams){
        return $stateParams.idEquip;
    },
    valoraciones: function(valoracions,$stateParams){
        return valoracions.query({idpartit:$stateParams.idPartit,idequip:$stateParams.idEquip,idperfil:$stateParams.idPerfil}).$promise;
    },
    
    partit: function($stateParams){
        return $stateParams.idPartit;
    }
      }
       
    }).state('home.votaciopublic', {
      url: '/votaciopublic/{idPartit}/{idPerfil}/{idEquip}',
     
          templateUrl: 'home/votacio/votaciopublic.tpl.html',
          controller: 'votaciopublicCtrl',
          controllerAs: 'vm',
      resolve:{
       
        resultat: function ( resultats,$stateParams){
          return resultats.query({idPartit :  $stateParams.idPartit}).$promise;
    }, items:function ( items,$stateParams){
          return items.query({idPerfil :  $stateParams.idPerfil}).$promise;
    },
    perfil: function($stateParams){
        return $stateParams.idPerfil;
    },
    equip: function($stateParams){
        return $stateParams.idEquip;
    },
    valoraciones: function(valoracions,$stateParams){
        return valoracions.query({idpartit:$stateParams.idPartit,idequip:$stateParams.idEquip,idperfil:$stateParams.idPerfil}).$promise;
    },
    
    partit: function($stateParams){
        return $stateParams.idPartit;
    }
      }
       
    });
  }
  }());